import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmEventType, MessageService, ConfirmationService } from 'primeng/api';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';
import { GlobalConstants } from 'src/app/utils/global-constants';

@Component({
    selector: 'app-folders-view',
    templateUrl: './folders-view.component.html',
    styleUrls: ['./folders-view.component.scss'],
})
export class FoldersViewComponent implements OnInit {
    @ViewChild('ft') ft: any;

    recreateBool: boolean = true;
    synchronizeBool: boolean = true;
    loadingFolders: boolean = true;
    selector: boolean = false;
    selectedType: string = '';

    types: any[] = [];
    missingFolders: any[] = [];
    syncFolders: any[] = [];
    selectedFolders: any[] = [];
    filteredArray: any[] = [];


    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private adminService: AdminService,
        private mainContentService: MainContentService
    ) {}

    ngOnInit(): void {
        let defaultTypes = GlobalConstants.typeOrder;

        for (let i = 0; i < defaultTypes.length; i++) {
            this.types.push({
                label: defaultTypes[i],
                value: defaultTypes[i],
            });
        }
    }

    getMissingFolders() {
        this.loadingFolders = true;
        this.selectedFolders = [];

        this.adminService.getMissingFolders(this.selectedType).subscribe((result: any) => {
            const folder = {
                'id': '',
            }
            
            this.missingFolders = result['folders'];
            this.filteredArray = result['folders'];
            this.syncFolders = [];

            this.loadingFolders = false;
        });
    }

    filterMissingFolders(event: any) {
        const searchKeyword = event.target.value;
      
        this.filteredArray = this.missingFolders.filter((element) => {
          return element.includes(searchKeyword);
        });      
    }
      

    onRowSelect(event: any) {
        this.syncFolders.push(event.data);
    }

    onRowUnselect(event: any) {
        this.syncFolders.splice(this.syncFolders.indexOf(event.data), 1);
    }

    synchronizeFolders(offline: boolean) {
        this.recreateBool = false;
        
        if(this.selectedFolders.length === this.filteredArray.length) {            
            this.syncFolders = this.selectedFolders;
        }

        let myMessage = 'Do you want to synchronize the following folders?<br><b>' + this.syncFolders + '</b>';
        if(offline) {
            myMessage = 'Do you want to synchronize the folders of type <b>' + this.selectedType + '</b> offline?';
            this.syncFolders = this.missingFolders;
        } else {
            if(this.syncFolders.length >= 20) {
                myMessage = 'There are more than 20 folders to synchronize for the type <b>' + this.selectedType + '</b>. They are going to be processed offline. Do you agree?';
            }
        }

        this.confirmationService.confirm({
            message: myMessage,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService
                    .syncMissingFolders(this.selectedType, this.syncFolders)
                    .subscribe({
                        next: (response) => {
                            let responseKeys = Object.keys(response);

                            if(!offline) {
                                for (let key of responseKeys) {
                                    if (response[key] === 'SUCCESS') {
                                        this.missingFolders.splice(
                                            this.missingFolders.indexOf(key),
                                            1
                                        );
                                        this.messageService.add({
                                            severity: 'info',
                                            summary: 'Confirmed',
                                            detail: 'Folder ' + key + ' synchronized correctly',
                                        });
                                    } else if(response[key] === 'FAILED') {
                                        this.messageService.add({
                                            severity: 'error',
                                            summary: 'Rejected',
                                            detail: 'ERROR: ' + key + ' not synchronized. Try again.',
                                        });
                                    }
                                }
                            } else {
                                this.messageService.add({
                                    severity: 'info',
                                    summary: 'Confirmed',
                                    detail: 'Folders synchronizing in the background',
                                });
                            }

                            this.syncFolders = [];
                            this.filteredArray = this.missingFolders;
                        },
                        error: () => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Rejected',
                                detail: 'Something went wrong. Try again.',
                            });
                        },
                    });
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Rejected',
                            detail: 'You have rejected',
                        });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Cancelled',
                            detail: 'You have cancelled',
                        });
                        break;
                }
            },
        });
    }

    recreateMainFolders() {
        this.recreateBool = true;

        this.confirmationService.confirm({
            message: 'Are you sure you want to recreate all the main folders of the domain?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService
                    .recreateMainFolders()
                    .subscribe({
                        next: () => {
                            this.messageService.add({
                                severity: 'info',
                                summary: 'Confirmed',
                                detail: 'Folders recreated successfully',
                            });
                        },
                        error: () => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Rejected',
                                detail: 'Something went wrong. Try again.',
                            });
                        },
                    });
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Rejected',
                            detail: 'You have rejected',
                        });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Cancelled',
                            detail: 'You have cancelled',
                        });
                        break;
                }
            },
        });

    }
}
