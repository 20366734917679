import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing-module';

import { AppComponent } from './app.component';
import { NavbarModule } from "./components/navbar/navbar.module";
import { UsersViewComponent } from './core/main-content/components/users-view/users-view.component';
import { MenubarModule } from 'primeng/menubar';
import { ContactsViewComponent } from './core/main-content/components/contacts-view/contacts-view.component';
import { LoginComponent } from './core/login/login.component';
import { MaterialModule } from './shared/material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginCustomerComponent } from './core/login-customer/login-customer.component';
import { TokenInterceptor } from './core/auth/token.interceptor';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { B2cEnableModalModule } from './shared/modals/b2c-enable-modal/b2c-enable-modal.module';
import { AuthService } from './core/auth/auth-service.service';
import { ActionsService } from './services/actions.service';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MatButtonModule } from '@angular/material/button';
import { TableToolBarComponent } from './components/table-tool-bar/table-tool-bar.component';
import { TableToolBarModule } from './components/table-tool-bar/table-tool-bar.module';
import { ButtonTableToolBarComponent } from './components/button-table-tool-bar/button-table-tool-bar.component';
import { ButtonTableToolBarModule } from './components/button-table-tool-bar/button-table-tool-bar.module';
import { EditUserViewComponent } from './core/main-content/components/edit-user-view/edit-user-view.component';
import { PrimeModule } from './shared/prime.module';
import { CreateUserViewComponent } from './core/main-content/components/create-user-view/create-user-view.component';
import { TypesViewComponent } from './core/main-content/components/types-view/types-view.component';
import { InvoicesViewComponent } from './core/main-content/components/invoices-view/invoices-view.component';
import { TouchViewComponent } from './core/main-content/components/touch-view/touch-view.component';
import { CategoriesViewComponent } from './core/main-content/components/categories-view/categories-view.component';
import { CookieService } from 'ngx-cookie-service';
import { FoldersViewComponent } from './core/main-content/components/folders-view/folders-view.component';
import { DownloadViewComponent } from './core/main-content/components/download-view/download-view.component';
import { LogDownloadViewComponent } from './core/main-content/components/log-download-view/log-download-view.component';
import { SupportDomainViewComponent } from './core/support-domain-view/support-domain-view.component';
import { DocumentLabelEnableModule } from './shared/modals/documentlabel-enable-modal/documentlabel-enable-modal.module';
import { RoutesViewComponent } from './core/main-content/components/routes-view/routes-view.component';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        UsersViewComponent,
        ContactsViewComponent,
        LoginComponent,
        LoginCustomerComponent,
        EditUserViewComponent,
        CreateUserViewComponent,
        TypesViewComponent,
        InvoicesViewComponent,
        TouchViewComponent,
        CategoriesViewComponent,
        FoldersViewComponent,
        DownloadViewComponent,
        LogDownloadViewComponent,
        SupportDomainViewComponent,
        RoutesViewComponent
        ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
		BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        MenubarModule,
        MaterialModule,
        PrimeModule,
        TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
        AngularFireModule.initializeApp(environment.firebase),
        NavbarModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        TableModule,
        DropdownModule,
        InputTextModule,
        ButtonModule,
        ContextMenuModule,
        InputSwitchModule,
        B2cEnableModalModule,
        DocumentLabelEnableModule,
        DialogModule,
        MatDialogModule,
        MessagesModule,
        OverlayPanelModule,
        MatButtonModule,
        TableToolBarModule,
        ButtonTableToolBarModule
    ],
    providers: [
        AuthService,
        ActionsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
        MessageService,
        MessagingService,
        {
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
        ConfirmationService,
        CookieService,
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'dd-MM-yyy' }
        }
    ]
})
export class AppModule { }
