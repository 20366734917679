<div *ngIf="!loadingInvoicesInfo" class="card-display">
    <div class="card-container">
        <p-card>
            <h2><b style="color: #e88900 !important;">{{ "Customer Info" | translate}}</b></h2>
            <ul class="info-list">
                <li>
                    <div class="customer-info-li">
                        <h5><b>{{ "Credits" | translate }} : </b></h5>
                        <span>{{ this.customerInfo.credit }}</span>
                    </div>
                </li>
                <li>
                    <div class="customer-info-li">
                        <h5><b>{{ "Last Credit Charge" | translate }} : </b></h5>
                        <span>{{ this.customerInfo.lastCreditChange }}</span>
                    </div>
                </li>
                <li>
                    <div class="customer-info-li">
                        <h5><b> {{ "Importing Mailbox" | translate }} : </b></h5>
                        <span>{{ this.customerInfo.importEmail }}</span>
                    </div>
                </li>
                <li>
                    <div class="customer-info-li">
                        <h5><b>{{"Invoice lines scan" | translate }} :</b></h5>
                        <span *ngIf="this.customerInfo.invoiceLinesScan">ON</span>
                        <span *ngIf="!this.customerInfo.invoiceLinesScan">OFF</span>
                    </div>
                </li>
                <hr>
                <div class="p-inputgroup" *ngIf="isInvoiceEnabled">
                    <span class="p-inputgroup-addon">
                        {{ "Add Credits" | translate }}
                    </span>
                    <input type="number" min="1" pInputText placeholder="Credits" [(ngModel)]="creditsToAdd"/>
                    <button class="add-email-button" (click)="addCredits(100)"  style="background-color: #a6a6a6; border: #a6a6a6;" type="button" pButton id="add-100-credit">+100</button>
                    <button class="add-email-button" (click)="addCredits(1000)" style="background-color: #a6a6a6; border: #a6a6a6;" type="button" pButton id="add-1000-credit">+1000</button>
                    <button class="add-email-button" (click)="addCredits()" type="button" pButton icon="pi pi-plus-circle"></button>

                    <p-toast></p-toast>
                    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
                </div>
            </ul>
        </p-card>
        <p-card>
            <h2 style="color: #e88900 !important;"><b style="color: #e88900 !important;">{{ "Allowed Emails to import" | translate }}</b></h2>
            <ul class="info-list">
                <li *ngFor="let email of emailsAllowed">
                    <div class="email-list">
                        {{ email }}
                        <p-button (click)="removeAllowedEmail(email)" class="delete-button" label="Delete" styleClass="p-button-danger"></p-button>
                    </div>
                </li>
                <hr>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        {{ "Allow Email" | translate }}
                    </span>
                    <input type="text" pInputText placeholder="Email" [(ngModel)]="newEmail"/>
                    <button class="add-email-button" (click)="addAllowedEmail()" type="button" pButton icon="pi pi-plus-circle"></button>

                    <p-toast></p-toast>
                    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
                </div>
            </ul>
        </p-card>
    </div>
    <p-card *ngIf="isInvoiceEnabled">
        <h2 style="color: #e88900 !important;"><b style="color: #e88900 !important;">{{ "Credit log" | translate }}</b></h2>
        <p-table 
            [value]="logs"
            [paginator]="true" 
            [rows]="10"
            [tableStyle]="{ 'min-width': '10rem' }"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ "Email" | translate }}</th>
                    <th>{{ "Date" | translate }}</th>
                    <th>{{ "Credit Added" | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-log>
                <tr>
                    <td>{{ log.email }}</td>
                    <td>{{ log.timestamp | date }}</td>
                    <td>{{ log.credit_added }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>