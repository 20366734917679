<div class="create-item-card">
    <p-card>
        <h1>Add user</h1>
        <div class="user-type-selectors centered-colored">
            <label>
                <input type="radio" name="userType" value="generic" (change)="changeCreationMode('generic')" checked > Generic
            </label>
            <label>
                <input type="radio" name="userType" value="standard" (change)="changeCreationMode('standard')"> Standard
            </label>
        </div>
        <form id="fields-form">
            @if(creationMode === 'standard') {
                    <p-dropdown [options]="userOptions" [(ngModel)]="selectedUser" name="user-dropdown" placeholder="Select an email"></p-dropdown><br>
            } @else if(creationMode === 'generic'){
                <form id="fields-form-generic">
                    <label for="userid">{{ "ID" | translate }}</label>
                    <input type="text" id="userid" name="userid" [(ngModel)]="userID"><br>
                
                    <label for="email">{{ "Email" | translate }}</label>
                    <input type="text" id="email" name="email" [(ngModel)]="email"><br>
                
                    <label for="password">{{ "Password" | translate }}</label>
                    <input type="password" id="password" name="password" [(ngModel)]="password"><br>
                </form>
            }
            
            <div class="user-access-switch">
                <div class="user-info">
                    <h5 style="font-weight: bold;">API</h5>
                    <p-inputSwitch [(ngModel)]="api" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
                <div class="user-info">
                    <h5 style="font-weight: bold;">PORTAL</h5>
                    <p-inputSwitch [(ngModel)]="portal" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
                <div class="user-info">
                    <h5 style="font-weight: bold;">ADMIN</h5>
                    <p-inputSwitch [(ngModel)]="admin" [ngModelOptions]="{ standalone: true }"></p-inputSwitch>
                </div>
            </div>
            
            <input class="disabled-button" type="submit" value="Add item" (click)="addNewUser($event)">
        </form>
    </p-card>
</div>

<div class="confirmation-button">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</div>
