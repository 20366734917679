<div *ngIf="!loadingLabels && !showInitializeButton" class="card-display">
    <div class="confirmation-button">
        <p-toast></p-toast>
        <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>

    <p-card>
        @if (labels.length > 0) {
            <p-table 
                #dt
                [filterDelay]="0" 
                [paginator]="true" 
                editMode="row" 
                [rows]="10" 
                [value]="labels"
                dataKey="UserID"
                [(selection)]="selectedLabelId"
                [tableStyle]="{ 'min-width': '50rem' }"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="enabled">{{ "Enabled" | translate }}<p-sortIcon field="enabled"></p-sortIcon></th>
                        <th>EN</th>
                        <th>FR</th>
                        <th>NL</th>
                        <th>Plenion ID</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-label>
                    <tr>
                        <td style="width: 5.5rem" #status>
                            <p-inputSwitch (onChange)="changeStatus(label)"  [(ngModel)]="label.enabled" [readonly]="!showAddCategory"></p-inputSwitch>
                        </td>
                        <td>{{ label.en }}</td>
                        <td>{{ label.fr }}</td>
                        <td>{{ label.nl }}</td>
                        <td>{{ label.plenionID }}</td>
                    </tr>
                </ng-template>
                <tr *ngIf="!loadingLabels" colspan="3" style="padding: 0">
                    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                </tr>
            </p-table>
        }
        @if (showAddCategory) {
            <hr>
            <h3><b style="color: #e88900 !important;">{{ "Add document label" | translate}}</b></h3>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">EN</span>
                <input type="text" pInputText placeholder="EN" [(ngModel)]="newLabel.en"/>
                <span class="p-inputgroup-addon">FR</span>
                <input type="text" pInputText placeholder="FR" [(ngModel)]="newLabel.fr"/>
                <span class="p-inputgroup-addon">NL</span>
                <input type="text" pInputText placeholder="NL" [(ngModel)]="newLabel.nl"/>
                <button class="add-label-button" (click)="createLabel()" type="button" pButton icon="pi pi-plus-circle"></button>
    
                <p-toast></p-toast>
                <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
            </div>
        }
    </p-card>
</div> 

<div *ngIf="!loadingLabels && showInitializeButton">
    <div class="p-inputgroup">
        <button class="initialize-button" (click)="initializeDocumentsLabels()" type="button" pButton>{{ "Initialize" | translate }}</button>
        <p-toast></p-toast>
        <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>
</div>
