<div class="folders-list">
    <div style="display: flex;">
        <h2><b style="color: #e88900 !important;">{{ "Select the type of the folders" | translate }}</b></h2>
        <p-button (click)="recreateMainFolders()" icon="pi pi-refresh" label="Recreate main folders"></p-button>
    </div>
    <p-dropdown 
        #typeSelector
        [options]="types" 
        [(ngModel)]="selectedType"
        placeholder="{{ 'Select a type' | translate }}"
        (onChange)="getMissingFolders()"
    >
    </p-dropdown>
    <div *ngIf="missingFolders.length != null && missingFolders.length > 0" class="datatable">
        <p-table 
            #ft
            [value]="filteredArray"
            (onRowSelect)="onRowSelect($event)"
		    (onRowUnselect)="onRowUnselect($event)"
            [tableStyle]="{ 'max-height': '20rem' }"
            [globalFilterFields]="['Folder ID']"
            selectionMode="multiple"
            [(selection)]="selectedFolders"
        >
            <ng-template pTemplate="caption">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="filterMissingFolders($event)" placeholder="Search keyword" />
                        <!-- <input type="text" placeholder="Search keyword" (input)="filterMissingFolders($event)" /> -->
                    </span>
                </div>
                <div style="display: flex;">
                    <div *ngIf="!loadingFolders && missingFolders.length != 0" class="confirmation-button">
                        <p-button (click)="synchronizeFolders(false)" icon="pi pi-check" label="Sync"></p-button>
                    </div>
                    <div *ngIf="!loadingFolders && missingFolders.length != 0" class="confirmation-button">
                        <p-button (click)="synchronizeFolders(true)" icon="pi pi-check" label="Sync Offline"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr *ngIf="loadingFolders == true">
                    <td [attr.colspan]="2">
                        <mat-progress-bar class="content-progress-bar" color="primary" mode="indeterminate"> </mat-progress-bar>
                    </td>
                </tr>
                <tr>
                    <th style="width: 20px;">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>{{"Folder ID" | translate }} </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-folder>
                <tr>
                    <td style="width: 20px;">
                        <p-tableCheckbox [value]="folder"></p-tableCheckbox>
                    </td>
                    <td>
                        {{ folder }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="!loadingFolders && missingFolders.length == 0">
        <h3><b>{{ "There aren't folders to synchronize for this type" | translate }}</b></h3>
    </div>
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</div>
